"use client";

import { useRouter } from "next/navigation";
import { useEffect } from "react";

const ErrorPage = () => {
	const router = useRouter();

	useEffect(() => {
		// Handle error logic here
		// You can use the router object to access the error information
	}, []);

	return (
		<div>
			<h1>Error</h1>
			<p>Oops! Something went wrong.</p>
			<button onClick={() => router.push("/")}>Go back to home</button>
		</div>
	);
};

export default ErrorPage;
